import React, { useState, useEffect } from 'react';
import { Drawer, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../logoyatri-removebg-preview.png';
import '../MasterLayout/Master.css';
import { DesktopOutlined, UserOutlined, FileTextOutlined, LogoutOutlined, CarOutlined, ToolOutlined, TeamOutlined, ProfileOutlined } from '@ant-design/icons';

const { Sider } = Layout;

interface SidebarProps {
  collapsed: boolean;
  onCollapse: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | boolean) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  hoverEffectActive: boolean;
  isSmallScreen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  onCollapse,
  onMouseEnter,
  onMouseLeave,
  hoverEffectActive,
  isSmallScreen,
}) => {
  const location = useLocation();
  const [activeMenuItemKey, setActiveMenuItemKey] = useState<string | null>(null);

  useEffect(() => {
    // Extract the path from the location object
    const currentPath = location.pathname;

    // Find the matching key in the menuItems array
    const matchedItem = menuItems.find(item => currentPath.startsWith(item.link));

    // Set the active key if a match is found
    if (matchedItem) {
      setActiveMenuItemKey(matchedItem.key);
    }
  }, [location.pathname]);
  const roleId = localStorage.getItem('role');

  const handleClick = (itemKey: string) => {
    setActiveMenuItemKey(itemKey);
    if (itemKey === '4') {
      localStorage.removeItem('token');
      localStorage.clear();
    }
  };

  const adminMenuItems = [
    { key: '1', icon: <DesktopOutlined />, text: 'Dashboard', link: '/dashboard' },
    { key: '2', icon: <UserOutlined />, text: 'Customers', link: '/customer' },
    { key: '3', icon: <FileTextOutlined />, text: 'Invoices', link: '/invoice' },
    { key: '5', icon: <CarOutlined />, text: 'Vehicles', link: '/vehicle' },
    { key: '6', icon: <ToolOutlined />, text: 'Maintenance', link: '/maintenance' },
    { key: '7', icon: <TeamOutlined  />, text: 'Subadmins', link: '/subadmin' },
    { key: '8', icon: <ProfileOutlined  />, text: 'Profile', link: '/profile' },
    { key: '4', icon: <LogoutOutlined  />, text: 'Logout ', link: '/login' },
  ];

  const subadminMenuItems = [
    { key: '1', icon: <DesktopOutlined />, text: 'Dashboard', link: '/dashboard' },
    { key: '2', icon: <UserOutlined />, text: 'Customers', link: '/customer' },
    { key: '3', icon: <FileTextOutlined />, text: 'Invoices', link: '/invoice' },
    { key: '5', icon: <CarOutlined />, text: 'Vehicles', link: '/vehicle' },
    { key: '6', icon: <ToolOutlined />, text: 'Maintenance', link: '/maintenance' },
    { key: '8', icon: <ProfileOutlined  />, text: 'Profile', link: '/profile' },
    { key: '4', icon: <LogoutOutlined  />, text: 'Logout ', link: '/login' }
  ];
  const menuItems = roleId === "1" ? adminMenuItems : subadminMenuItems;

  const collapsedWidth = isSmallScreen ? 0 : 80;

  const firstStyle = isSmallScreen ? 'mobile-screen-sidebar' : 'desktop-screen-sidebar';
  const secondStyle = hoverEffectActive && !isSmallScreen ? 'mobile-screen-sidebar' : 'desktop-screen-sidebar';

  return (
    <>
      {isSmallScreen ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={onCollapse}
          width="200px"
          open={collapsed}
          maskClosable
          style={{ backgroundColor: '#343A40' }}
        >
          {/* Your mobile sidebar content */}
        </Drawer>
      ) : (
        <Sider
          className={`${firstStyle} ${secondStyle}`}
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            style={{
              top: 0,
              position: 'sticky',
              display: 'block',
              textAlign: 'center',
              zIndex: 999,
              backgroundColor: '#001529',
            }}
          >
            <img
              src={Logo}
              alt="ImageLogo"
              style={{
                maxHeight: '70px',
                margin: 'auto',
              }}
            />
          </div>
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[activeMenuItemKey].filter(Boolean) as string[]}
            style={{
              minHeight: 'calc(100vh - 70px)',
            }}
          >
            {menuItems.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => handleClick(item.key)}
                className={activeMenuItemKey === item.key ? 'sidebar-menu-active' : 'sidebar-menu'}
              >
                <Link to={item.link} style={{ textDecoration: 'none' }}>
                  {item.text}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      )}
    </>
  );
};

export default Sidebar;