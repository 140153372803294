import React, { useEffect, useState } from "react";
import { Col, Row, Input, InputNumber, Table, Space, Button, Card, Form, message, DatePicker, Select } from "antd";
import { invoice, getCustomers, getVehicle } from "../utils/API";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Option } from "antd/es/mentions";
import moment from 'moment';


interface Item {
    isNew: any;
    key: number;
    itemDetails: string;
    quantity: any;
    rate: any;
    amount: any;
}


let invoiceCounter = 0; // Initialize counter for auto-incrementing part
const formatInvoiceNumber = () => {
    invoiceCounter++; // Increment the counter for each call to generate a new invoice number
    const year = new Date().getFullYear(); // Get the current year
    const formattedNumber = `OTW/${year}-${(year + 1).toString().slice(2)}/${invoiceCounter.toString().padStart(4, '0')}`;
    return formattedNumber;
};


const Newgenarate: React.FC = () => {

    const defaultItems: Item[] = [
        { key: Date.now(), itemDetails: '', quantity: 1, rate: 0, amount: 0, isNew: false },
        { key: Date.now() + 1, itemDetails: 'DRIVER ALLOWANCE', quantity: 1, rate: 300, amount: 300, isNew: false },
        { key: Date.now() + 2, itemDetails: 'TOLL- PARKING', quantity: 1, rate: 250, amount: 250, isNew: false },
    ];
    const navigate = useNavigate()
    const [customerData, setCustomerData] = useState([]);
    const [itemData, setItemData] = useState<Item[]>(defaultItems);
    const [vehicleData, setVehicleData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [discountedAmount, setDiscountedAmount] = useState<number>(0);
    const [roundedGstAmount, setRoundedGstAmount] = useState<number>(0);
    const [form] = Form.useForm(); // Use Form hook to get form instance


    const calculateDueDate = (invoiceDate: any, terms: any) => {
        const dueDate = new Date(invoiceDate);
        dueDate.setDate(dueDate.getDate() + terms);
        return dueDate;
    };

    const handleTermsChange = (terms: any) => {
        // Get the current invoice date value from the form
        const invoiceDate = form.getFieldValue('invoiceDate');

        // Check if the invoice date is selected
        if (invoiceDate) {
            // Calculate the due date based on the selected terms
            const dueDate = calculateDueDate(invoiceDate, terms);
            const formattedDueDate = dueDate.toISOString().split('T')[0];

            // Convert the formatted date string to a moment object
            const momentDueDate = moment(formattedDueDate);

            // Set the moment object in the DatePicker
            form.setFieldsValue({ dueDate: momentDueDate });
        } else {
            message.error("Please select Invoice Date first.");
        }
    };

    const handleInvoiceDateChange = (date: any, dateString: string) => {

    };

    const handleSubmit = (values: any) => {
        setIsLoading(true);
        values.itemData = itemData;
        values.totalAmount = totalAmount;
        values.discountedAmount = discountedAmount;
        values.GST_PERCENTAGES = 5;
        invoice(values).then((response: any) => {
            message.success(response.data.message);
            navigate("/invoice")
        }).catch((error) => {
            setIsLoading(false);
            if (error.message === 'Item data is required.') {
                message.error('Item data is required. Please add items before submitting.');
            } else {
                message.error(error.response.data.message);
            }
        });
    };

    useEffect(() => {
        FetchCustomerData();
        FetchVehicleData();
    }, []);
    const calculateAmounts = () => {
        let GST_PERCENTAGES = 5
        // Calculate total amount based on itemData
        const calculatedTotalAmount = itemData.reduce((total, item) => total + item.amount, 0);
        setTotalAmount(calculatedTotalAmount);

        // Calculate GST amount based on the total amount
        const gstAmount = Math.floor((GST_PERCENTAGES / 100) * calculatedTotalAmount);

        // Update state values accordingly
        setRoundedGstAmount(gstAmount);
        const total = Math.round(calculatedTotalAmount + gstAmount);
        setDiscountedAmount(total);
    };

    useEffect(() => {
        // Call the calculateAmounts function whenever dependencies change
        calculateAmounts();
    }, [itemData, totalAmount]);




    const FetchCustomerData = () => {
        getCustomers("")
            .then((response) => {
                // Filter out the record with email "admin@gmail.com" from the response data
                const filteredData = response.data.filter((customer: any) => customer.Email !== 'admin@gmail.com');
                setCustomerData(filteredData);
            })
            .catch((error) => {
                message.error("Error fetching customer data. Please try again.");
                navigate('/dashboard');
            });
    };

    const FetchVehicleData = () => {
        getVehicle("")
            .then((response) => {
                const filteredData = response.data;
                setVehicleData(filteredData);
            })
            .catch((error) => {
                message.error("Error fetching Vehicle data. Please try again.");
                navigate('/dashboard');
            });
    };


    const columns = [
        {
            title: "Item Details",
            dataIndex: "itemDetails",
            key: "itemDetails",
            width: "30%",
            render: (text: any, record: Item) => {
                if (record.isNew) { // Only render a text box for a new item
                    return (
                        <Input
                            className="rounded border border-1"
                            style={{ width: "100%" }}
                            value={record.itemDetails}
                            onChange={(e) => handleItemInputChange(record.key, 'itemDetails', e.target.value)}
                        />
                    );
                } else { // Render Select for existing items
                    return (
                        <Select
                            className="rounded border border-1"
                            style={{ width: "100%" }}
                            value={record.itemDetails}
                            onChange={(value) => handleItemInputChange(record.key, 'itemDetails', value)}
                        >
                            {record.itemDetails ? null : (
                                <Option value="" disabled>Select Vehicle</Option>
                            )}
                            {vehicleData.map((vehicle: any) => (
                                <Option key={vehicle._id} value={vehicle.vehicleName}>{vehicle.vehicleName}</Option>
                            ))}
                        </Select>
                    );
                }
            }
        },
        {
            title: "",
            key: "gap",
            width: "10%",
            render: () => null, // Render nothing
        },
        {
            title: "QTY",
            dataIndex: "quantity",
            key: "quantity",
            width: "10%",
            render: (text: any, record: Item) => (
                <InputNumber
                    value={record.quantity}
                    formatter={(value) => (value !== undefined && /^\d*\.?\d*$/.test(value) ? value : '')}
                    parser={(value) => (value !== undefined ? value.replace(/[^\d\.]/g, '') : '')}
                    onChange={(value) => handleItemInputChange(record.key, 'quantity', value)}
                />
            ),
        },

        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            width: "10%",
            render: (text: any, record: Item) => (
                <InputNumber
                    value={record.rate}
                    formatter={(value) => (value !== undefined && /^\d*\.?\d*$/.test(value) ? value : '')}
                    parser={(value) => (value !== undefined ? value.replace(/[^\d\.]/g, '') : '')}
                    onChange={(value) => handleItemInputChange(record.key, 'rate', value)}
                />
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            width: "10%",
            render: (text: any, record: Item) => (
                <InputNumber value={record.amount} disabled />
            ),
        },

        {
            title: "Action",
            key: "action",
            width: "5%", // Adjust the width as per your requirement
            render: (text: any, record: Item) => (
                <Space size="middle">
                    <Button onClick={() => handleRemoveItem(record.key)} icon={<CloseOutlined />} style={{ color: 'red', borderColor: 'red' }}></Button>
                </Space>
            ),
        },
    ];

    const handleItemInputChange = (key: number, dataIndex: string, value: string | number) => {
        setItemData((prevData) =>
            prevData.map((item) =>
                item.key === key
                    ? {
                        ...item,
                        [dataIndex]: value,
                        amount:
                            dataIndex === 'quantity'
                                ? (value as number) * item.rate
                                : dataIndex === 'rate'
                                    ? item.quantity * (value as number)
                                    : item.amount,
                    }
                    : item
            )
        );
    };


    const handleAddItem = () => {
        setItemData(prevData => [...prevData, { key: Date.now(), itemDetails: '', quantity: 1, rate: 0, amount: 0, isNew: true }]);
    };
    const handleRemoveItem = (key: number) => {
        setItemData(prevData => prevData.filter(item => item.key !== key));
    };

    return (
        <div className="">
            <Row className="m-2" align="middle">
                <Col span={18}>
                    <h2 className="text-2xl font-bold">Invoice</h2>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <Row>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-end align-items-center"
                        >
                            <button className="btn btn-primary d-flex justify-content-center align-items-center m-1" onClick={() => window.history.back()}>
                                <IoArrowBackOutline className="me-2" />
                                <div>Back</div>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form onFinish={handleSubmit} form={form} className="bg-white">
                <Card style={{ backgroundColor: "#ffffff" }}>
                    <Row

                        className="border border-warning-2 p-5 bg-white rounded-md"
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Customer Name<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="customer_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Seleact Customer Name",
                                            },
                                        ]}
                                    >
                                        <Select size="large" placeholder="Select Customer">
                                            {customerData.map((customer: any) => (
                                                <Option key={customer._id} value={customer._id}>
                                                    {customer.customerName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                     

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Invoice Date<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="invoiceDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Invoice Date",
                                            },
                                        ]}
                                    >

                                        <DatePicker
                                            picker="date"
                                            format="YYYY-MM-DD"
                                            onChange={handleInvoiceDateChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Terms</label>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name="terms">
                                        <Select
                                            size="middle"
                                            placeholder="Select Terms"
                                            onChange={(value) => handleTermsChange(parseInt(value, 10))}
                                        >
                                            <Option value="15">Net 15</Option>
                                            <Option value="30">Net 30</Option>
                                            <Option value="45">Net 45</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={1}></Col>
                                <Col span={2} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Due Date<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={3}>
                                    <Form.Item
                                        name="dueDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Due Date",
                                            },
                                        ]}
                                    >
                                        <DatePicker picker="date" format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>



                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Sales Person</label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="salesperson">
                                        <Input size="large" className="rounded border border-1" placeholder="Sales-Person" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Subject</label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="subject">
                                        <Input size="large" className="rounded border border-1" placeholder="subject" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>




                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>

                            <Row>
                                <Col span={24}>
                                    <h3 className="font-bold mt-4">Item Table</h3>
                                    <Table columns={columns} dataSource={itemData} pagination={false} />
                                    <Button onClick={handleAddItem} icon={<PlusOutlined />} className="mt-4">Add Item</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={15}></Col>
                        <Col span={6} className="bg-white mt-3">
                            <Card>
                                <div className="d-flex justify-content-between">
                                    <span className="font-bold">Sub Total (₹)</span>
                                    <span className="font-bold flax-end">{totalAmount}</span>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <span className="font-bold">CGST (2.5%)</span>
                                    <span className="font-bold flax-end">+{roundedGstAmount / 2}</span>
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                    <span className="font-bold">SGST (2.5%)</span>
                                    <span className="font-bold flax-end">+{roundedGstAmount / 2}</span>
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                    <span className="font-bold">Total (₹)</span><span className="font-bold">{discountedAmount}</span>
                                </div>
                            </Card>
                        </Col>


                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-center mt-3"
                        >
                            <button className="btn btn-primary btn-lg" disabled={isLoading}>
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                        </Col>

                    </Row>
                </Card>
            </Form>
        </div>
    );
};

export default Newgenarate;