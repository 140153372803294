import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Spin } from 'antd';

const PrivateRoute: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
        if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
    setLoading(false); 
  }, []);

  if (loading) {
    return <Spin className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }} />;
  }

  return <>{authenticated ? <Outlet /> : <Navigate to="/login" />}</>;
};

export default PrivateRoute;
