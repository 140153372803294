import React, { useEffect } from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { getCustomerByID, updateCustomer } from "../utils/API";
import { useNavigate } from "react-router-dom";



const Profile: React.FC = () => {

    const navigate = useNavigate()

    const [form] = Form.useForm(); // Use Form hook to get form instance
    const [isLoading, setIsLoading] = React.useState(false);
    const user_id = localStorage.getItem('customerId') || '';
    const id = user_id.replace(/["']/g, '');

    useEffect(() => {

        if (id) {
            const fetchData = async () => {
                try {
                    const existingData = await getCustomerByID(id);
                    form.setFieldsValue({
                        ...existingData,
                    });
                } catch (error) {
                    message.error("Error fetching data. Please try again.");
                    navigate('/dashboard')
                }
            };
            fetchData();
        }
    }, [id, form]);

    const handleSubmit = async (values: any) => {
        const currentId = form.getFieldValue('_id');
        if (currentId) {
            setIsLoading(true);
            await updateCustomer(currentId, values)
                .then((response) => {
                    setIsLoading(false);
                    message.success("Profile updated successfully");
                    navigate('/profile')
                })
                .catch((error) => {
                    setIsLoading(false);
                    message.error("Failed to submit data. Please try again.");
                    navigate('/profile')
                });
        } else {
            setIsLoading(false);
            message.error("Failed Fetch data. Please try again.");
            navigate('/profile')
        }
    };


    return (
        <div className="">
            <Row className="m-2" align="middle">
                <Col span={18}>
                    <h2 className="text-2xl font-bold">Profile</h2>
                </Col>
            </Row>
            <Form onFinish={handleSubmit} form={form} className="bg-white">
                <Card style={{ backgroundColor: "#ffffff" }}>
                    <Row

                        className="border border-warning-2 p-5 bg-white rounded-md"
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Email<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="Email"
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "Please Enter Email",
                                                }
                                            ]
                                        }
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Email" disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Name<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="customerName"
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "Please Enter Name",
                                                }
                                            ]
                                        }
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder=" Name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Contact<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="contact"
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "Please Enter Contact",
                                                },
                                                {
                                                    pattern: new RegExp(/^[0-9]+$/),
                                                    message: "Please Enter Valid Contact",
                                                }
                                            ]
                                        }
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Contact" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Password<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="password">
                                        <Input size="large" className="rounded border border-1" placeholder="password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-center"
                        >
                            <button className="btn btn-primary btn-lg" disabled={isLoading}>
                                {isLoading ? 'Updating...' : 'Update'}
                            </button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    );
};

export default Profile;