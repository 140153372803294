import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Card, Select, DatePicker } from "antd";
import { getDataByID, updateData, getVehicle } from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import dayjs from "dayjs";


const { Option } = Select;
interface Vehicle {
  _id: string;
  vehicleName: string;
  // Add other properties as needed
}

const MaintenanceEditForm: React.FC = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm(); // Use Form hook to get form instance

  const [vehicleData, setVehicleData] = useState<Vehicle[]>([]);
  const [maintenanceType, setMaintenanceType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchVehicleData = () => {
    getVehicle("") // Pass any required parameters
      .then((response) => {
        setVehicleData(response.data);
      })
      .catch((error) => {
        message.error("Error fetching Vehicle data. Please try again.");
        navigate('/dashboard');
      });
  };

  useEffect(() => {
    fetchVehicleData(); // Fetch vehicle data when the component mounts
  }, []);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const existingData = await getDataByID(id);
          const vehicleName = vehicleData.find(Vehicle => Vehicle._id === existingData.vehicle_id._id)?.vehicleName;
          form.setFieldsValue({
            ...existingData,
            maintenanceType: existingData.maintenanceType === 0 ? "0" : "1", // Set default value based on maintenanceType
            vehicle_id: existingData.vehicle_id._id, // Set the vehicle_id
            created_date: dayjs(existingData.created_date),
          });
        } catch (error) {
          message.error("Error fetching data. Please try again.");
          navigate('/dashboard');
        }
      };

      fetchData();
    }
  }, [id, form]);


  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue('_id');
    if (currentId) {
      setIsLoading(true);
      await updateData(currentId, values)
        .then((response) => {
          message.success("Data updated successfully");
          window.history.back();
        })
        .catch((error) => {
          setIsLoading(false);
          message.error("Failed to submit data. Please try again.");
          window.history.back();
        });
    } else {
      message.error("Failed Fetch data. Please try again.");
      window.history.back();
    }
  };


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Maintenance Edit</h2>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button className="btn btn-primary d-flex justify-content-center align-items-center m-1" onClick={() => window.history.back()}>
                <IoArrowBackOutline className="me-2" />
                <div>Back</div>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row

            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Vehicle<span className="text-danger">*</span></label>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vehicle_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select the vehicle",
                      },
                    ]}
                  >
                    <Select size="large" placeholder="Select vehicle">
                      {vehicleData.map((vehicle: any) => (
                        <Option key={vehicle._id} value={vehicle._id}>
                          {vehicle.vehicleName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Maintenance Type<span className="text-danger">*</span></label>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="maintenanceType"
                    rules={[
                      {
                        required: true,
                        message: "Please select the Maintenance Type",
                      },
                    ]}
                  >
                    <Select size="large" placeholder="Select maintenance type" onChange={(value) => setMaintenanceType(value)}>
                      <Option value="0">Maintenance</Option>
                      <Option value="1">Fuel</Option>
                    </Select>
                  </Form.Item>
                </Col>

              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Amount<span className="text-danger">*</span></label>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={"amount"}
                    rules={[
                      {
                        required: true,
                        message: `Please enter the Amount`,
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers",
                      }]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Amount" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Date<span className="text-danger">*</span></label>
                </Col>
                <Col span={8}>
                <Form.Item
                    name="created_date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Join Date",
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>

                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn btn-primary btn-lg" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default MaintenanceEditForm;