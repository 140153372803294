import Dashboard from "./Pages/Dashboard";
import Forms from "./Pages/Forms";
import CustomerMasterForm from "./Pages/CutomerMasterForm";
import CustomerEditForm from "./Pages/CustomerEditForm";
import CustomerTable from "./Tables/CustomerMasterTable";
import Login from "./Pages/Auth/Login";
import Vehicle from "./Pages/Vehicle";
import InvoiceTable from "./Pages/Invoice";
import Newgenarate from "./Pages/Newgenarate";
import InvoiceEditForm from "./Pages/InvoiceEditForm";
import VehicleMasterForm from "./Pages/VehicleForm";
import VehicleEditForm from "./Pages/VehicleEditForm";
import Subadmin from "./Pages/Subadmin";
import SubadminEditForm from "./Pages/SubadminEditForm";
import Maintenance from "./Pages/Maintenance";
import Maintenanceform from "./Pages/MaintenanceForm";
import MaintenanceEditForm from "./Pages/MaintenanceEditForm";
import NotAccess from "./Components/NotAccess";
import Profile from "./Pages/Profile";

const roleId = localStorage.getItem("role");

const AdminRoutes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        exact: true,
    },
    // not found
    { path: "*", name: 'Not Found', component: Dashboard },

    //dashboard
    { path: "/dashboard", exact: true, name: 'Dashboard', component: Dashboard },

    //vehicle
    { path: "/vehicle", exact: true, name: 'Vehicle', component: Vehicle },
    {
        path: "/vehicle/add",
        exact: true,
        name: 'Vehicle Form',
        component: roleId === "1" ? VehicleMasterForm : NotAccess
    },
    {
        path: "/vehicle/:id/edit",
        name: "Vehicle Form",
        component: roleId === "1" ? VehicleEditForm : NotAccess,
        exact: true
    },

    //invoice
    { path: "/invoice", exact: true, name: 'invoice', component: InvoiceTable },
    {
        path: "/invoice/add",
        exact: true,
        name: 'invoice',
        component: roleId === "1" ? Newgenarate : NotAccess
    },
    {
        path: "/invoice/:id/edit",
        name: "Invoice Form",
        component: roleId === "1" ? InvoiceEditForm : NotAccess,
        exact: true
    },

    //customer
    { path: "/customer", name: "Customers", component: CustomerTable, exact: true },
    {
        path: "/customer/add",
        name: "Customer Form",
        component: roleId === "1" ? CustomerMasterForm : NotAccess,
        exact: true
    },
    {
        path: "/customer/:id/edit",
        name: "Customer Form",
        component: roleId === "1" ? CustomerEditForm : NotAccess,
        exact: true
    },

    //subadmin
    { path: "/subadmin", exact: true, name: 'Subadmin', component: roleId === "1" ? Subadmin : NotAccess },
    {
        path: "/subadmin/add",
        name: "Subadmin Form",
        component: roleId === "1" ? Forms : NotAccess,
        exact: true
    },
    {
        path: "/subadmin/:id/edit",
        name: "Subadmin Edit Form",
        component: roleId === "1" ? SubadminEditForm : NotAccess,
        exact: true
    },

    //maintenance
    { path: "/maintenance", exact: true, name: 'Maintenance', component: Maintenance },
    {
        path: "/maintenance/add",
        name: "maintenance Form",
        component: roleId === "1" ? Maintenanceform : NotAccess,
        exact: true
    },
    {
        path: "/maintenance/:id/edit",
        name: "maintenance edit",
        component: roleId === "1" ? MaintenanceEditForm : NotAccess,
        exact: true
    },
    { path: "/profile", exact: true, name: 'Profile', component: Profile },
];

export { AdminRoutes };