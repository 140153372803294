import React from 'react';
import { Card, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "../Styles/Card.css";

interface MyCardProps {
    title: string;
    dropdownText?: string;
    value?: string | number;
}

const MyCard: React.FC<MyCardProps> = ({ title, dropdownText, value }) => {

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Today',
        },
        {
            key: '2',
            label: 'Yesterday',
        },
    ];

    return (
        <Card className="my-card-container">
            <div className="my-card-title">{title}
                {dropdownText && (
                    <div className="my-card-dropdown">
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <div className='custom-dropdown-content' style={{ cursor: 'pointer' }}>
                                {dropdownText}<DownOutlined />
                            </div>
                        </Dropdown>
                    </div>
                )}
            </div>
            <div className="my-card-title" style={{ fontWeight: '700', fontSize: '40px' }}>{value}</div>
        </Card>
    );
};

export default MyCard;
