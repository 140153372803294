import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Master from './MasterLayout/Master';
import { AdminRoutes } from './Route';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Login from './Pages/Auth/Login';
import NotFound from './Components/NotFound';

const App: React.FC = () => {

  const token = localStorage.getItem('token');

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<NotFound />} />
        <Route element={<PrivateRoute />}>
      
            {AdminRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<Master children={<route.component />} />}
              />
            ))}
        </Route>

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
