import React, { useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from "react-icons/fa";
import { Card, Col, Input, Modal, Row, Spin, Table, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { deleteVehicle, getVehicle } from '../utils/API';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { SearchOutlined } from '@ant-design/icons';


const Vehicle: React.FC = () => {
  const [vehicleData, setvehicleData] = useState<[]>([]);
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const roleId = localStorage.getItem('role');


  const Fetchvehicle = (searchText: string) => {
    setLoading(true);
    getVehicle(searchText)
      .then((response: any) => {
        // Reverse the data array before setting it in the state
        const reversedData = response.data.reverse();
        setvehicleData(reversedData);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error('Error fetching data. Please try again later');
        setLoading(false); // Set loading to false
        navigate('/dashboard');
      });
  };


  React.useEffect(() => {
    Fetchvehicle(searchText);
  }, [searchText]);

  const showDeleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete this vehicle?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteVehicle(id);
          message.success('Vehicle deleted successfully');
          Fetchvehicle(searchText);
        } catch (error) {
          // Handle error
          message.error('Error deleting vehicle. Please try again later');
        }
      },
    });
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };


  const handleEditRedirect = (record: any) => {
    // Use history push to navigate to the edit page with the record ID
    navigate(`/vehicle/${record._id}/edit`);
  };

  const actionColumn = roleId === "1" ? {
    title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
    dataIndex: 'action',
    render: (text: any, record: any) => (
      <div className='d-flex'>
        <FaEdit
          className='me-4'
          style={{ fontSize: '20px', color: 'green', cursor: 'pointer' }}
          onClick={() => handleEditRedirect(record)}
        />
        <AiFillDelete
          style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }}
          onClick={() => showDeleteConfirmation(record._id)}
        />
      </div>
    ),
  } : {};

  const columns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>No.</div>,
      dataIndex: 'index',
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Vehicle Name</div>,
      dataIndex: 'vehicleName',
    },

    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Used Km</div>,
      dataIndex: 'usedkm',
    },
    actionColumn
  ]



  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Vehicles</h2>

        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
          <Row>
            <Col xs={0} sm={0} md={0} xl={14} xxl={14}>
            </Col>
            <Col xs={24} sm={24} md={10} xl={6} xxl={6}>
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end"
                placeholder="Search..."
                allowClear
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={6} xl={4} xxl={4} className="d-flex justify-content-end">
              {
                roleId === "1" ? (
                  <Link to={"/vehicle/add"} >
                    <button className="btn btn-info d-flex justify-content-end align-items-center ">
                      <RiAddBoxFill style={{ fontSize: "15px" }} />
                      <div className="ms-2">Add  Vehicle</div>
                    </button>
                  </Link>
                ) : (<></>)
              }
            </Col>

          </Row>
        </Col>
      </Row>
      <div>
        {
          loading ?
            <Spin className="d-flex justify-content-center align-items-center min-h-screen">
              <div>&nbsp;</div>
            </Spin>
            :
            <>

              <Row >
                <Col style={{ backgroundColor: '#ffffff', borderRadius: '12px' }} xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Card className='bg-white border ' style={{ borderRadius: '12px' }}>
                    <Table
                      scroll={{ x: 1000 }}
                      dataSource={loading ? [] : vehicleData}
                      columns={columns}
                      pagination={{ pageSize: 10 }}
                    />
                  </Card>
                </Col>
              </Row>
            </>
        }
      </div>
    </>
  );
};

export default Vehicle;