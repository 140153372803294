// src/HelloWorld.tsxCopy code
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { getvehicleByID, updatevehicle } from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";



const VehicleEditForm: React.FC = () => {

  const navigate = useNavigate()

  const { id } = useParams();
  const [form] = Form.useForm(); // Use Form hook to get form instance
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const existingData = await getvehicleByID(id);
          form.setFieldsValue({
            ...existingData,
          });
        } catch (error) {
          message.error("Error fetching vehicle data. Please try again.");
          navigate('/dashboard')
        }
      };
      fetchData();
    }
  }, [id, form]);

  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue('_id');
    if (currentId) {
      setIsLoading(true);
      await updatevehicle(currentId, values)
        .then((response) => {
          message.success(response.message);
          window.history.back();
        })
        .catch((error) => {
          setIsLoading(false);
          message.error("Failed to submit Vehicle data. Please try again.");
          window.history.back();
        });
    } else {
      setIsLoading(false);
      message.error("Failed Fetch vehicle data. Please try again.");
      window.history.back();
    }
  };


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Vehicle Edit</h2>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button className="btn btn-primary d-flex justify-content-center align-items-center m-1" onClick={() => window.history.back()}>
                <IoArrowBackOutline className="me-2" />
                <div>Back</div>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row

            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Vehicle Name<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vehicleName"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Vehicle Name",
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Vehicle Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Used Km<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="usedkm"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Used Km",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Please enter only numbers for Used Km",
                        },
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Used Km" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn btn-primary btn-lg" disabled={isLoading}>
                {isLoading ? 'Updating...' : 'Update Vehicle'}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default VehicleEditForm;
