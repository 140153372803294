import React from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { addSubadmin } from "../utils/API";
import { useNavigate } from "react-router-dom";

const Forms: React.FC = () => {

    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    const handleSubmit = (values: any) => {
        setIsLoading(true);
        addSubadmin(values)
            .then((response: any) => {
                message.success(response.data.message);
                navigate("/subadmin");
            })
            .catch((error) => {
                setIsLoading(false);
                // message.error(error.response.data.error);
                message.error("This email is already registered please try with another email.");
            });
    };

    return (
        <div className="">
            <Row className="m-2" align="middle">
                <Col span={18}>
                    <h2 className="text-2xl font-bold">Subadmin Add</h2>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <Row>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-end align-items-center"
                        >
                            <button
                                className="btn btn-primary d-flex justify-content-center align-items-center m-1"
                                onClick={() => window.history.back()}
                            >
                                <IoArrowBackOutline className="me-2" />
                                <div>Back</div>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form onFinish={handleSubmit} className="bg-white">
                <Card style={{ backgroundColor: "#ffffff" }}>
                    <Row
                        className="border border-warning-2 p-5 bg-white rounded-md"
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Name<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="customerName"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the Name",
                                            },
                                        ]}
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Email<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the Email",
                                            }]}
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Email" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Password<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the Password",
                                            }]}
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Password" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Contact<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="contact"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the Contact",
                                            },
                                            {
                                                pattern: /^[0-9]*$/,
                                                message: "Please enter only numbers",
                                            }]}
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Contact" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-center"
                        >
                            <button className="btn btn-primary btn-lg" disabled={isLoading}>
                                {isLoading ? 'Adding...' : 'Submit'}
                            </button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    );
};

export default Forms;