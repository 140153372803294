import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Card, Select, DatePicker } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { addMaintanance, getVehicle } from "../utils/API";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const Maintenanceform: React.FC = () => {
    const navigate = useNavigate();
    const [vehicleData, setVehicleData] = useState([]);
    const [maintenanceType, setMaintenanceType] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const FetchVehicleData = () => {
        getVehicle("")
            .then((response) => {
                const filteredData = response.data;
                setVehicleData(filteredData);
            })
            .catch((error) => {
                message.error("Error fetching Vehicle data. Please try again.");
                navigate('/dashboard');
            });
    };

    useEffect(() => {
        FetchVehicleData();
    }, []);

    const handleSubmit = (values: any) => {
        setIsLoading(true);
        addMaintanance(values)
            .then((response: any) => {
                message.success(response.data.message);
                navigate("/maintenance");
            })
            .catch((error) => {
                setIsLoading(false);
                message.error("Something went wrong. Please try again later.");
                navigate("/maintenance");
            });
    };

    return (
        <div className="">
            <Row className="m-2" align="middle">
                <Col span={18}>
                    <h2 className="text-2xl font-bold">Maintenance Add</h2>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <Row>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-end align-items-center"
                        >
                            <button
                                className="btn btn-primary d-flex justify-content-center align-items-center m-1"
                                onClick={() => window.history.back()}
                            >
                                <IoArrowBackOutline className="me-2" />
                                <div>Back</div>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form onFinish={handleSubmit} className="bg-white">
                <Card style={{ backgroundColor: "#ffffff" }}>
                    <Row
                        className="border border-warning-2 p-5 bg-white rounded-md"
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Vehicle<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="vehicle_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select the vehicle",
                                            },
                                        ]}
                                    >
                                        <Select size="large" placeholder="Select vehicle">
                                            {vehicleData.map((vehicle: any) => (
                                                <Option key={vehicle._id} value={vehicle._id}>{vehicle.vehicleName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Maintenance Type<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="maintenanceType"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select the Maintenance Type",
                                            },
                                        ]}
                                    >
                                        <Select size="large" placeholder="Select maintenance type" onChange={(value) => setMaintenanceType(value)}>
                                            <Option value="0">Maintenance</Option>
                                            <Option value="1">Fuel</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        {/* Amount input code... */}
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Amount<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name={"amount"}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please enter the Amount`,
                                            },
                                            {
                                                pattern: /^[0-9]*$/,
                                                message: "Please enter only numbers",
                                            }]}
                                    >
                                        <Input size="large" className="rounded border border-1" placeholder="Amount" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Date<span className="text-danger">*</span></label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="created_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select the Date",
                                            },
                                        ]}
                                    >
                                        <DatePicker size="large" />
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <button className="btn btn-primary btn-lg" disabled={isLoading}>
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    );
};

export default Maintenanceform;