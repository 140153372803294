import { Form, Input, message } from "antd";
import React from "react";
import { login } from "../../utils/API";
import { Label } from "semantic-ui-react";

const Login: React.FC = () => {
const [isLoading, setIsLoading] = React.useState(false);
  const onFinish = (values: any) => {
    setIsLoading(true);
    login(values)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user.customerName));
          localStorage.setItem("role", JSON.stringify(res.data.user.role_id));
          localStorage.setItem("customerId", JSON.stringify(res.data.user._id));
          message.success("Login successfully!");
          window.location.href = "/dashboard";
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error.response?.data?.message || "An error occurred");
      });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm border border-blue-500 rounded-lg">

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="m-4"
          >
            <label className="font-bold m-2">Email address</label>
            <Form.Item
              name="Email"
              rules={[{ required: true, message: "Please enter your email!" }]}
            >
              <Input placeholder="Enter Email" className="rounded" />
            </Form.Item>
            <Label className="font-bold m-2">Password</Label>
            <Form.Item
              className="mt-2 mb-2"
              name="password"
              rules={[{ required: true, message: "Please enter your password!" }]}
            >
              <Input.Password placeholder="Enter Password" size="large" className="rounded" />
            </Form.Item>

            <Form.Item>
              <button
                style={{ width: "100%", marginTop: "10px" }}
                disabled={isLoading}
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >{isLoading ? 'Sign in...' : 'Sign in'}</button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;