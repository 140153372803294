import React, { useState } from 'react';
import { RiAddBoxFill } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from "react-icons/fa";
import { Card, Col, Modal, Button, Row, Spin, Table, message, Select, DatePicker, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { deleteData, getMaintanance, getVehicle } from '../utils/API';
import 'antd/dist/reset.css'; // Import Ant Design styles
import * as XLSX from "xlsx"; // Import xlsx library
import { BiExport } from 'react-icons/bi';
const { Option } = Select;

const Maintenance: React.FC = () => {
  const [maintenceData, setMaintenanceData] = useState<[]>([]);
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [vehicleData, setVehicleData] = useState<{ _id: any; vehicleName: any; }[]>([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [fromDate, setFromDate] = useState(null); // State variable for from date
  const [toDate, setToDate] = useState(null);     // State variable for to date
  const [type, setType] = useState(null);
  const navigate = useNavigate();
  const roleId = localStorage.getItem('role');


  const fetchMaintenanceData = (searchText: string) => {
    setLoading(true);
    getMaintanance(searchText, fromDate, toDate, type) // Pass fromDate and toDate to the API call
      .then((response: any) => {
        const reversedData = response.data.reverse();
        setMaintenanceData(reversedData);
        const totalAmount = reversedData.reduce((total: any, invoice: { amount: any; }) => total + invoice.amount, 0);
        setTotalInvoiceAmount(totalAmount);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error('Error fetching maintenance data. Please try again later');
        setLoading(false);
        navigate('/dashboard');
      });
  };

  const fetchVehicleData = () => {
    getVehicle("")
      .then((response) => {
        const filteredData = response.data;
        const allOption = [{ _id: "", vehicleName: "All" }];
        setVehicleData([...allOption, ...filteredData]);
      })
      .catch((error) => {
        message.error("Error fetching Vehicle data. Please try again.");
        navigate('/dashboard');
      });
  };

  React.useEffect(() => {
    fetchMaintenanceData(searchText);
    fetchVehicleData();
  }, [searchText, fromDate, toDate]);


  const showDeleteConfirmation = (id: number) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete this Data?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteData(id);
          message.success('Data deleted successfully');
          fetchMaintenanceData(searchText);
        } catch (error) {
          // Handle error
          message.error('Error deleting Data. Please try again later');
        }
      },
    });
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleEditRedirect = (record: any) => {
    navigate(`/maintenance/${record._id}/edit`);
  };

  const handleFromDateChange = (date: any) => {
    setFromDate(date ? date.format('YYYY-MM-DD') : null);
  };

  const handleToDateChange = (date: any) => {
    setToDate(date ? date.format('YYYY-MM-DD') : null);
  };
  const handlemaintainceType = (type: any) => {
    setType(type);
    setLoading(true); // Set loading state to true while fetching data
    getMaintanance(searchText, fromDate, toDate, type)
      .then((response: any) => {
        const reversedData = response.data.reverse();
        setMaintenanceData(reversedData);
        const totalAmount = reversedData.reduce((total: any, invoice: { amount: any; }) => total + invoice.amount, 0);
        setTotalInvoiceAmount(totalAmount);
        setLoading(false); // Set loading state to false after data is fetched
      })
      .catch((error: any) => {
        message.error('Error fetching maintenance data. Please try again later');
        setLoading(false); // Set loading state to false in case of error
        navigate('/dashboard');
      });
  };

  const actionColumn = roleId === "1" ?
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <div className='d-flex'>
          <FaEdit
            className='me-4'
            style={{ fontSize: '20px', color: 'green', cursor: 'pointer' }}
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }}
            onClick={() => showDeleteConfirmation(record._id)}
          />
        </div>
      ),
    } : {};

  const handleExport = () => {
    if (maintenceData.length > 0) {
      // Exclude "id" and "inventory image" fields from the exported data
      const dataToExport: Array<Partial<any>> = maintenceData.map((item: any) => {
        const formatedDate = new Date(item.created_date).toLocaleDateString();
        return {
          "Vehicle name": item.vehicle_id.vehicleName,
          " Maintenance Type": item.maintenanceType === 0 ? "Maintainance" : "Fuel",
          "Amount": item.amount,
          "Date": formatedDate
        };
      });
      // Convert the modified data to a worksheet
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "maintainance Data");
      // Save the workbook to a file
      XLSX.writeFile(wb, "maintainance.xlsx");
    } else {
      message.warning("No data to export");
    }
  };



  const columns = [
    {
      title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>No.</div>,
      dataIndex: 'index',
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Vehicle Name</div>,
      dataIndex: 'vehicle_id',
      render: (vehicle: any) => vehicle && vehicle.vehicleName ? vehicle.vehicleName : 'N/A'
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Maintenance Type</div>,
      dataIndex: 'maintenanceType',
      render: (value: number) => (
        <span>{value === 0 ? "Maintenance" : "Fuel"}</span>
      ),
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Amount</div>,
      dataIndex: 'amount',
      render: (value: number) => (
        <span>
          ₹ {value}
        </span>
      ),
    },
    {
      title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Date</div>,
      dataIndex: 'created_date',
      render: (value: string) => {
        const formattedDate = new Date(value).toISOString().split('T')[0];
        return <span>{formattedDate}</span>;
      },
    },
    actionColumn
  ]

  const handleClear = () => {
    window.location.reload();
  };
  
  return (
    <>
      <Row className="m-2 font-bold" align={"middle"}>
        <h2 className="text-2xl">Maintenance</h2>
      </Row>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={24}
          xxl={24}
          className="d-flex justify-content-start"
        >
          <Col xs={24} sm={24} md={14} xl={14} xxl={14}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <label className="font-bold">Vehicles</label>
                <Select
                  size="middle"
                  placeholder="Select vehicle"
                  onChange={handleSearch}
                  style={{ width: '100%' }}
                >
                  {vehicleData.map((vehicle: any) => (
                    <Option key={vehicle._id} value={vehicle._id}>
                      {vehicle.vehicleName}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={6}>
                <label className="font-bold">Type</label>
                <Select
                  size="middle"
                  placeholder="Maintenance type"
                  onChange={(value) => handlemaintainceType(value)} // Pass an object with 'type' and 'value'
                  style={{ width: '100%' }}
                >
                  <Option value="0">Maintenance</Option>
                  <Option value="1">Fuel</Option>
                </Select>
              </Col>

              <Col span={6}>
                <label className="font-bold">From Date</label>
                <Form.Item name="fromDate">
                  <DatePicker style={{ width: "100%" }} onChange={handleFromDateChange} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <label className="font-bold">To Date</label>
                <Form.Item name="toDate">
                  <DatePicker style={{ width: "100%" }} onChange={handleToDateChange} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={6} xl={6} xxl={6} className="d-flex justify-content-center align-items-center">

            <Col xs={24} sm={24} md={16} xl={8} xxl={8} className="d-flex justify-content-center">
              <button className="btn btn-primary btn-sm" onClick={handleClear}>Reset</button>
            </Col>
            <Col xs={24} sm={24} md={8} xl={16} xxl={16} className="d-flex justify-content-end">
              <h3 style={{ fontWeight: 'bold' }}>Total Amount: ₹{totalInvoiceAmount}</h3>
            </Col>

          </Col>
          {
            roleId === "1" ? (
              <>
                <Col xs={24} sm={24} md={4} xl={4} xxl={4} className="d-flex justify-content-end mt-3">
                  <button className='btn btn-info d-flex justify-content-center align-items-center mb-4 me-2' onClick={handleExport}>
                    <BiExport style={{ fontSize: "15px" }} />
                    <div className="ms-2">Export</div>
                  </button>
                  <Link to={"/maintenance/add"}>
                    <button className="btn btn-info d-inline-flex align-items-center">
                      <RiAddBoxFill style={{ fontSize: "15px" }} />
                      <div className="ms-2">Add</div>
                    </button>
                  </Link>
                </Col>

              </>
            ) : (<></>)
          }
        </Col>
      </Row>
      <div>
        {
          loading ?
            <Spin className="d-flex justify-content-center align-items-center min-h-screen">
              <div>&nbsp;</div>
            </Spin>
            :
            <>
              <Row >
                <Col style={{ backgroundColor: '#ffffff', borderRadius: '12px' }} xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Card className='bg-white border ' style={{ borderRadius: '12px' }}>
                    <Table
                      scroll={{ x: 1000 }}
                      dataSource={loading ? [] : maintenceData}
                      columns={columns}
                      pagination={{ pageSize: 10 }}
                    />
                  </Card>
                </Col>
              </Row>
            </>
        }
      </div>
    </>
  );
};

export default Maintenance;