import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, InputNumber, DatePicker, message, Card, Button, Select } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { getInvoiceByID, updateinvoice, getVehicle, getCustomers } from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Key } from "antd/es/table/interface";


type ItemData = {
    rate: number;
    amount: number;
    quantity: number;
    key: Key;
    gst: number;
}

const InvoiceEditForm: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [vehicleData, setVehicleData] = useState([]);
    const [customerData, setCustomerData] = useState<{
        customerName: any; _id: string; name: string;
    }[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const existingData = await getInvoiceByID(id);

                form.setFieldsValue({
                    customerName: existingData.customer_id.customerName,
                    dueDate: dayjs(existingData.dueDate),
                    invoiceDate: dayjs(existingData.invoiceDate),
                    invoiceNumber: existingData.invoiceNumber,
                    salesperson: existingData.salesperson,
                    subject: existingData.subject,
                    totalAmount: existingData.totalAmount,
                    itemData: existingData.itemData,
                    GST: existingData.GST_PERCENTAGES,
                    discountedAmount: existingData.discountedAmount,
                });

            } catch (error) {
                message.error("Error fetching Invoice data. Please try again.");
                navigate(-1);
            }
        };
        const FetchVehicleData = () => {
            getVehicle("")
                .then((response) => {
                    const filteredData = response.data;
                    setVehicleData(filteredData);
                })
                .catch((error) => {
                    message.error("Error fetching Vehicle data. Please try again.");
                    navigate('/dashboard');
                });
        };
        const fetchCustomerData = async () => {
            try {
                const response = await getCustomers("");
                setCustomerData(response.data);
            } catch (error) {
                message.error("Error fetching Customer data. Please try again.");
            }
        };

        if (id) {
            fetchData();
            FetchVehicleData();
            fetchCustomerData();
        }
    }, [id, form]);

    const handleSubmit = async (values: any) => {
        const currentId = id;
        if (currentId) {
            try {
                setIsLoading(true);
                await updateinvoice(currentId, values);
                message.success('Invoice updated successfully');
                navigate(-1); // Go back one step in history
            } catch (error) {
                setIsLoading(false);
                message.error('Failed to update invoice. Please try again.');
            }
        } else {
            setIsLoading(false);
            message.error('Failed to submit invoice data. Please try again.');
        }
    };

    const updateTotalAmount = () => {
        const itemData = form.getFieldValue('itemData') as ItemData[];
        const totalAmount = itemData.reduce((total, item) => total + item.amount, 0);
        form.setFieldsValue({ totalAmount });
        updateDiscountedAmount(totalAmount);
    };

    const updateDiscountedAmount = (totalAmount: number) => {
        const gst = form.getFieldValue('GST') || 0;
        const discountedAmount = Math.floor(totalAmount + (totalAmount * gst) / 100);
        form.setFieldsValue({ discountedAmount });
    };



    const handleQuantityChange = (index: number, quantity: number) => {
        const itemData = form.getFieldValue('itemData') as ItemData[];
        if (itemData && itemData[index]) {
            const updatedItemData = [...itemData];
            updatedItemData[index].quantity = quantity;
            updatedItemData[index].amount = quantity * updatedItemData[index].rate;
            form.setFieldsValue({ itemData: updatedItemData });
            updateTotalAmount();
        }
    };

    const handleRateChange = (index: number, rate: number) => {
        const itemData = form.getFieldValue('itemData') as ItemData[];
        if (itemData && itemData[index]) {
            const updatedItemData = [...itemData];
            updatedItemData[index].rate = rate;
            updatedItemData[index].amount = rate * updatedItemData[index].quantity;
            form.setFieldsValue({ itemData: updatedItemData });
            updateTotalAmount();
        }
    };

    const handleRemoveItem = (index: number) => {
        const itemData = form.getFieldValue('itemData') as ItemData[];
        if (itemData && itemData[index]) {
            const updatedItemData = itemData.filter((item, i) => i !== index);
            form.setFieldsValue({ itemData: updatedItemData });
            updateTotalAmount();
        }
    };

    const handleItemInputChange = (index: number, field: string, value: any) => {
        const itemData = form.getFieldValue('itemData');
        const updatedItemData = [...itemData];
        updatedItemData[index][field] = value;
        form.setFieldsValue({ itemData: updatedItemData });
    };


    // ... (other existing code)


    return (
        <div className="">
            <Row className="m-2" align="middle">
                <Col span={18}>
                    <h2 className="text-2xl font-bold">Invoice Edit</h2>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <Row>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-end align-items-center"
                        >
                            <button
                                className="btn btn-primary d-flex justify-content-center align-items-center m-1"
                                onClick={() => navigate(-1)}
                            >
                                <IoArrowBackOutline className="me-2" />
                                <div>Back</div>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form onFinish={handleSubmit} form={form} className="bg-white">
                <Card style={{ backgroundColor: "#ffffff" }}>
                    <Row
                        className="border border-warning-2 p-5 bg-white rounded-md"
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Invoice Number<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="invoiceNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Invoice Number",
                                            },
                                        ]}
                                    >
                                        <Input size="large" placeholder="Invoice Number" disabled={true} className="rounded border border-1" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                                    <label className="font-bold">Customer Name<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="customerName"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Customer Name",
                                            },
                                        ]}
                                    >
                                        <Select
                                            size="large"
                                            placeholder="Select Customer"
                                            onChange={(value) => {
                                                const selectedCustomer = customerData.find(customer => customer._id === value);
                                                form.setFieldsValue({
                                                    customerName: selectedCustomer ? selectedCustomer.customerName : '',
                                                    customer_id: value,
                                                });
                                            }}
                                        >
                                            {customerData.map((customer) => (
                                                <Select.Option key={customer._id} value={customer._id}>
                                                    {customer.customerName}
                                                </Select.Option>
                                            ))}
                                        </Select>

                                    </Form.Item>
                                    <Form.Item name="customer_id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row className="bg-white">
                                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Invoice Date<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="invoiceDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Invoice Date",
                                            },
                                        ]}
                                    >

                                        <DatePicker
                                            picker="date"
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={1}></Col>
                                <Col span={2} className="d-flex justify-content-start me-4 bg-white mb-5">
                                    <label className="font-bold">Due Date<span className="text-red-500">*</span></label>
                                </Col>
                                <Col span={3}>
                                    <Form.Item
                                        name="dueDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Due Date",
                                            },
                                        ]}
                                    >
                                        <DatePicker picker="date" format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>


                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Card>
                                <Row gutter={[16, 16]} className="bg-#fafafa mb-3">
                                    <Col span={8}>
                                        <label className="font-bold">Item Details</label>
                                    </Col>
                                    <Col span={2}>

                                    </Col>
                                    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <label className="font-bold">QTY</label>
                                    </Col>
                                    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <label className="font-bold">Rate</label>
                                    </Col>
                                    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <label className="font-bold">Amount</label>
                                    </Col>
                                    <Col span={2}>
                                        <label className="font-bold">Action</label>
                                    </Col>
                                </Row>
                                <Form.List name="itemData">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                <Row key={key} gutter={[16, 16]} className="bg-#fafafa">
                                                    <Col span={8}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'itemDetails']}
                                                            fieldKey={[fieldKey as Key, 'itemDetails' as Key]} // Cast fieldKey to Key
                                                        >
                                                            <Input
                                                                className="rounded border border-1"
                                                                style={{ width: "100%" }}
                                                                placeholder="Enter Item Details"
                                                                value={form.getFieldValue(['itemData', key, 'itemDetails'])} // Set the value to itemDetails
                                                                onChange={(e) => handleItemInputChange(key, 'itemDetails', e.target.value)}
                                                            />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={2}>
                                                    </Col>
                                                    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'quantity']}
                                                            fieldKey={[fieldKey as Key, 'quantity' as Key]}
                                                            rules={[{ pattern: /^\d*$/, message: 'Please enter a valid number' }]}
                                                        >
                                                            <InputNumber
                                                                formatter={(value: number | undefined) => (value !== undefined ? value.toString() : '')}
                                                                parser={(value: string | undefined) => {
                                                                    if (value !== undefined) {
                                                                        const numericValue = value.replace(/[^\d]/g, '');
                                                                        return /^\d*$/.test(numericValue) ? parseInt(numericValue, 10) : 0;
                                                                    }
                                                                    return NaN;
                                                                }}
                                                                onChange={(value) => handleQuantityChange(key as number, value as number)}
                                                            />
                                                        </Form.Item>
                                                    </Col>


                                                    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'rate']}
                                                            fieldKey={[fieldKey as Key, 'rate' as Key]}
                                                            rules={[{ pattern: /^\d+(\.\d+)?$/, message: 'Please enter a valid number' }]}
                                                        >
                                                            <InputNumber
                                                                formatter={(value: number | undefined) => (value !== undefined ? value.toString() : '')}
                                                                parser={(value: string | undefined) => {
                                                                    if (value !== undefined) {
                                                                        const numericValue = value.replace(/[^\d.]/g, ''); // Allow only digits and dot
                                                                        return parseFloat(numericValue); // Parse the numeric value as a float
                                                                    }
                                                                    return NaN;
                                                                }}

                                                                onChange={(value) => handleRateChange(key as number, value as number)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'amount']}
                                                            fieldKey={[fieldKey as Key, 'amount' as Key]}
                                                        >
                                                            <InputNumber disabled={true} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Button onClick={() => handleRemoveItem(name)} icon={<CloseOutlined />} style={{ color: 'red', borderColor: 'red' }}></Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Form.Item>
                                                <Button onClick={() => add({ key: Date.now(), itemDetails: '', quantity: 1, rate: 0, amount: 0 })} icon={<PlusOutlined />} className="mt-1">
                                                    Add Item
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Col>

                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Row>

                                <Col span={15}>
                                    {/* Content before the Card */}
                                </Col>
                                <Col span={8}>

                                    <Card className="mt-3">
                                        {/* Total Amount */}
                                        <Row className="bg-white mb-1">
                                            <Col span={10} className="d-flex justify-content-start me-4">
                                                <label className="font-bold">Sub Total (₹)</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="totalAmount"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Total Amount is required",
                                                        },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder="Total Amount" disabled className="rounded border border-1" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="bg-white mb-1">
                                            <Col span={10} className="d-flex justify-content-start me-4">
                                                <label className="font-bold">GST (%)</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="GST">
                                                    <Input size="large" placeholder="GST" disabled className="rounded border border-1" />
                                                </Form.Item>

                                            </Col>
                                        </Row>


                                        {/* Discounted Amount */}
                                        <Row className="bg-white mb-1">
                                            <Col span={10} className="d-flex justify-content-start me-4">
                                                <label className="font-bold">Total (₹)</label>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="discountedAmount"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Discounted Amount is required",
                                                        },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder="Discounted Amount" disabled className="rounded border border-1" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            xxl={24}
                            className="d-flex justify-content-center align-items-center mt-3"
                        >
                            <button className="btn btn-primary btn-lg" disabled={isLoading}>
                                {isLoading ? 'Updating...' : 'Update Invoice'}
                            </button>

                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    );
};

export default InvoiceEditForm;