import React, { useState } from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { addvehicle } from "../utils/API";
import { useNavigate } from "react-router-dom";

const VehicleMasterForm: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    addvehicle(values)
      .then((response: any) => {
        message.success(response.data.message);
        navigate("/vehicle");
      })
      .catch((error) => {
        setIsLoading(false);
        message.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Vehicle Add</h2>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center m-1"
                onClick={() => window.history.back()}
              >
                <IoArrowBackOutline className="me-2" />
                <div>Back</div>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Vehicle Name<span className="text-danger">*</span></label>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vehicleName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the vehicle name",
                      },
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Vehicle Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Used Km<span className="text-danger">*</span></label>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="usedkm"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the Used Km",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter only numbers for Used Km",
                      },
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Used Km" />
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn btn-primary btn-lg" disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add Vehicle'}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default VehicleMasterForm;