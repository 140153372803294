import React, { useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row } from 'antd';
import { useLocation } from 'react-router-dom';

const { Header } = Layout;

interface NavbarProps {
  collapsed: boolean;
  toggleSidebar: () => void;
  disableHoverEffect: () => void;
}
interface StaticRoute {
    kind: 'static';
    header: string;
  }
  
  interface DynamicRoute {
    kind: 'dynamic';
    header: (params: Record<string, string>) => string;
  }
  
  type RouteHeaderMap = {
    [key: string]: StaticRoute | DynamicRoute;
  };
  
const Navbar: React.FC<NavbarProps> = ({ collapsed, toggleSidebar, disableHoverEffect }) => {
  const userName = localStorage.getItem('user') || ''; // default to empty string if user is not available
  const formattedUserName = userName.replace(/["']/g, '');
  const location = useLocation();
  const [headerName, setHeaderName] = useState('');

  const ButtonStyle = {
    marginTop: '18px',
    background: 'transparent',
  };
  useEffect(() => {
    // Map your routes to header names
    const routeHeaderMap: RouteHeaderMap = {
      '/login': { kind: 'static', header: 'Login' },
      '/': { kind: 'static', header: 'Dashboard' },
      '/charts': { kind: 'static', header: 'Charts' },
      '/customer/:id/edit': { kind: 'dynamic', header: (params) => `Edit Customer ` },
    };
  
    // Set the header name based on the current route
    const matchingRoute = Object.keys(routeHeaderMap).find((route) => {
      const routePattern = new RegExp(`^${route.replace(/:[^/]+/g, '[^/]+')}$`);
      return routePattern.test(location.pathname);
    });
    setHeaderName(matchingRoute ? (
        routeHeaderMap[matchingRoute].kind === 'dynamic'
          ? (routeHeaderMap[matchingRoute] as DynamicRoute).header({
              id: 'extracted_id',
            })
          : (routeHeaderMap[matchingRoute] as StaticRoute).header
      ) : '');
      
  }, [location.pathname]);
  

  return (
    <Header className='header'>
      <Row >
        <Col span={2}>
          <Button
            type="text"
            icon={<MenuOutlined className='header-icon' /> }
            style={ButtonStyle}
            onClick={() => {
              toggleSidebar();
              disableHoverEffect();
            }}
            className='m-2' 
          />
        </Col>
        <Col span={18} className='mt-1'>
          <span className='font-semibold text-2xl ' >{headerName}</span>
        </Col>
        <Col span={4}>
          <span className='font-semibold text-2xl p-3 text-gray-500 font-roboto'>
            Hey {formattedUserName}
          </span>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
