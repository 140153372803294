// src/HelloWorld.tsxCopy code
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { addCustomer } from "../utils/API";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { Select } from "antd";

interface Country {
  country_code: string;
  country_code3: string;
  country: string;
  capital: string;
  region: string;
  subregion: string;
  states: State[];
}

interface State {
  country_code: string;
  country: string;
  subdivision: string | null;
  cities: string[];
}


const { Option } = Select;
const CustomerMasterForm: React.FC = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch country data from JSON file
    fetch("/uploads/country.json")
      .then((response) => response.json())
      .then((data: { countries: Country[] }) => {
        setCountries(data.countries);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const handleSelectionChange = (
    selectedValue: string,
    type: "country" | "state" | "city"
  ) => {
    switch (type) {
      case "country":
        const selectedCountryData = countries.find(
          (country) => country.country_code === selectedValue
        );
        setStates(selectedCountryData?.states || []);

        break;

      case "state":
        const selectedStateData = states.find(
          (state) => state.country_code === selectedValue
        );
        break;

      case "city":
        break;

      default:
        break;
    }
  };

  const navigate = useNavigate()

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    addCustomer(values).then((response: any) => {
      message.success(response.data.message);
      navigate("/customer")
    }).catch((error) => {
      setIsLoading(false);
      message.error("This email is already registered please try with another email.");
    })
  };



  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Customer</h2>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-end align-items-center">
              <button className="btn btn-primary d-flex justify-content-center align-items-center m-1" onClick={() => window.history.back()}>
                <IoArrowBackOutline className="me-2" />
                <div>Back</div>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Customer Name<span className="text-danger">*</span></label>
                </Col>
                <Col span={2}>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please select a title",
                      },
                    ]}
                    initialValue="Ms"
                  >
                    <Select size="large" placeholder="Title">
                      <Option value="Ms">Ms.</Option>
                      <Option value="Mr">Mr.</Option>
                      <Option value="Mrs">Mrs.</Option>
                      <Option value="Dr">Dr.</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={9}>
                  <Form.Item name="customerName"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Customer Name"
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Customer Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Customer Email<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="Email"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Email"
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Contact<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contact"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile no"
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers"
                      },
                      {
                        len: 10,
                        message: "Mobile number must be 10 digits"
                      }
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Mobile" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">PAN<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="PAN"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter PAN"
                      },
                      {
                        pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Please enter a valid PAN card number"
                      }
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Enter PAN" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>


            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5"
                >
                  <label className="font-bold">
                    Country <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    rules={[
                      { required: true, message: "Please select a country" },
                    ]}
                  >
                    <Select
                      className="border-none"
                      allowClear

                      size="large"
                      onChange={(value) =>
                        handleSelectionChange(value, "country")}
                      placeholder="Select Country"
                      filterOption={(input, option) =>
                        (option?.children as any)
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countries.map((country) => (
                        <Option
                          key={country.country_code}
                          value={country.country_code}
                        >
                          {country.country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5"
                >
                  <label className="font-bold">
                    State <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="state"
                    rules={[
                      { required: true, message: "Please select a state" },
                    ]}
                  >
                    <Select
                      className="w-100 border-none"
                      size="large"
                      onChange={(value) =>
                        handleSelectionChange(value, "state")
                      }
                      placeholder="Select State"
                    >
                      {states.map((state) => (
                        <Option
                          key={state.country_code}
                          value={state.country_code}
                        >
                          {state.country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">City<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="city"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter City"
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="City" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Address<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="address"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Address"
                        }
                      ]
                    }
                  >
                    <Input.TextArea size="large" placeholder="Address" cols={3} rows={3} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Zip Code<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ZipCode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Zip Code",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter only numbers",
                      },
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Zip Code" />
                  </Form.Item>
                </Col>

              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">GST No</label>
                </Col>
                <Col span={12}>
                  <Form.Item name="GSTNO">
                    <Input size="large" className="rounded border border-1" placeholder="GST NO" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Payment Terms</label>
                </Col>
                <Col span={12}>
                  <Form.Item name="PaymentTerms"
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Payment Terms" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Remarks</label>
                </Col>
                <Col span={12}>
                  <Form.Item name="Remarks">
                    <Input size="large" className="rounded border border-1" placeholder="Enter Remarks" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn btn-primary btn-lg" disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add Customer'}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default CustomerMasterForm;
