import axios from 'axios';

// export  const API = axios.create({
//   baseURL: 'http://103.86.16.70:5000/api',
// })


export  const API = axios.create({
  baseURL: 'http://103.86.16.203:5000/api',
})








API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
})

export const login = async (data: {
  Email: any;
  password: any;
}) => {
  try {
    const response = await API.post('/customers/login', data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addCustomer = async (data: {
  title: string;
  customerName: string;
  Email: string;
  contact: string;
  address: string;
  city: string;
  state: string;
  country: string;
  ZipCode: string;
  PAN: string;
  GSTNO: string;
  PaymentTerms: string;
  Documents: File;  // Assuming you're uploading a file
  Remarks: string;
}) => {
  try {
    const response = await API.post('/customers', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCustomers = async (searchText: string) => {
  try {
    const response = await API.get('/customers', {
      params: {
        search: searchText,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCustomer = async (id: number) => {
  try {
    const response = await API.delete(`/customers/${id}`)
    return response
  } catch (error) {
    throw error;
  }
}
export const updateCustomer = async (customerId: number, data: any): Promise<any> => {
  try {
    const response = await API.put(`/customers/${customerId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getCustomerByID = async (id: any) => {

  try {
    const response = await API.get(`/customers/${id}`)
    return response.data
  } catch (error) {
    throw error;
  }
}


export const invoice = async (data: {
  customerName: string;
  customer_id: string;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  salesperson: string;
  subjeact: string;
  itemData: any;
  totalAmount: number;
  discount: number;
  discountedAmount: number;
  GST_PERCENTAGES: number;
}) => {
  try {
    if (!data.itemData || data.itemData.length === 0) {
      throw new Error('Item data is required.'); // Throw an error if itemData is empty
    }
    const response = await API.post('/invoice', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getInvoice = async (searchText: string, fromDate?: any, toDate?: any) => {
  try {
    const response = await API.get('/getinvoice', {
      params: {
        search: searchText,
        fromDate: fromDate,
        toDate: toDate,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteinvoice = async (id: number) => {
  try {
    const response = await API.delete(`/invoice/${id}`)
    return response
  } catch (error) {
    throw error;
  }
}
export const getInvoiceByID = async (id: any) => {

  try {
    const response = await API.get(`/invoice/${id}`)
    return response.data
  } catch (error) {
    throw error;
  }
}
export const updateinvoice = async (customerId: any, data: any): Promise<any> => {
  try {
    if (!data.itemData || data.itemData.length === 0) {
      await deleteinvoice(customerId); // Call the function to delete the record
    } else {
      const response = await API.put(`/invoice/${customerId}`, data);
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const addvehicle = async (data: {
  vehicleName: string;
  usedkm: string;

}) => {
  try {
    const response = await API.post('/vehicles/add', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getVehicle = async (searchText: string) => {
  try {
    const response = await API.get('/vehicles/get', {
      params: {
        search: searchText,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatevehicle = async (vehicleId: number, data: any): Promise<any> => {
  try {
    const response = await API.put(`/vehicles/${vehicleId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVehicle = async (id: number) => {
  try {
    const response = await API.delete(`/vehicles/${id}`)
    return response
  } catch (error) {
    throw error;
  }
}

export const getvehicleByID = async (id: any) => {
  try {
    const response = await API.get(`/vehicles/${id}`)
    return response.data
  } catch (error) {
    throw error;
  }
}

export const addSubadmin = async (data: {
  customerName: string;
  Email: string;
  password: string;
  contact: string;
}) => {
  try {
    const response = await API.post('/subadmin', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubadmin = async (searchText: string) => {
  try {
    const response = await API.get('/subadmin', {
      params: {
        search: searchText,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addMaintanance = async (data: {
  vehicle_id: string;
  maintenanceType: Number;
  amount: Number;
  created_date: Date;
}) => {
  try {
    const response = await API.post('/maintenance', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMaintanance = async (searchText: string, fromDate?: any, toDate?: any, type?: any) => {
  try {
    const response = await API.get('/maintenance', {
      params: {
        search: searchText,
        fromDate: fromDate,
        toDate: toDate,
        type: type,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteData = async (id: number) => {
  try {
    const response = await API.delete(`/maintenance/${id}`)
    return response
  } catch (error) {
    throw error;
  }
}

export const getDataByID = async (id: any) => {
  try {
    const response = await API.get(`/maintenance/${id}`)
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updateData = async (Id: number, data: any): Promise<any> => {
  try {
    const response = await API.put(`/maintenance/${Id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};