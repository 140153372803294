import React, { useState, useEffect } from 'react';
import { Col, Row, message } from 'antd';
import { Link } from 'react-router-dom';
import MyCard from '../Content/Card';
import { getCustomers, getInvoice, getVehicle } from '../utils/API'; // Assuming this is the correct import path
import '../Styles/Dashboard.css';

const Dashboard: React.FC = () => {
    const [customerCount, setCustomerCount] = useState<number>(0);
    const [invoiceCount, setInvoiceCount] = useState<number>(0);
    const [vehicleCount, setVehicleCount] = useState<number>(0);
    const [sale, setSale] = useState(0);

    useEffect(() => {
        // Fetch customer count
        getCustomers("")
            .then((response: { data: string | any[]; }) => {
                setCustomerCount(response.data.length);
            })
            .catch((error: any) => {
                message.error('Error fetching customer data');
            });

        // Fetch invoice count
        getInvoice("")
            .then((response: { data: string | any[] }) => {
                if (Array.isArray(response.data)) {
                    setInvoiceCount(response.data.length);
                    const totalAmount = response.data.reduce((total: any, invoice: any) => {
                        return total + invoice.discountedAmount;
                    }, 0);
                    setSale(totalAmount);
                } else {
                    // Handle the case when response.data is not an array
                    console.error('Response data is not an array:', response.data);
                }
            })
            .catch((error: any) => {
                message.error('Error fetching invoice data');
            });


        //Fetch vehicle count
        getVehicle("")
            .then((response: { data: string | any[]; }) => {
                setVehicleCount(response.data.length);
            })
    }, []);

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={8}>
                    <Link to="/customer">
                        <MyCard title="Customers" value={customerCount.toString()} />
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Link to="/invoice">
                        <MyCard title="Invoices" value={invoiceCount.toString()} />
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Link to="/vehicle">
                        <MyCard title="Vehicles" value={vehicleCount.toString()} />
                    </Link>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={8}>
                    <Link to="/invoice">
                        <MyCard title="Sale" value={sale} />
                    </Link>
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;