// src/HelloWorld.tsxCopy code
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { getCustomerByID, updateCustomer } from "../utils/API";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import dayjs from "dayjs";



const CustomerEditForm: React.FC = () => {

  const navigate = useNavigate()

  const { id } = useParams();
  const [form] = Form.useForm(); // Use Form hook to get form instance
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const existingData = await getCustomerByID(id);
          form.setFieldsValue({
            ...existingData,
            join_date: dayjs(existingData.join_date),
          });
        } catch (error) {
          message.error("Error fetching customer data. Please try again.");
        }
      };
      fetchData();
    }
  }, [id, form]);

  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue('_id');
    if (currentId) {
      setIsLoading(true);
      await updateCustomer(currentId, values)
        .then((response) => {
          // if (response.status === 200) {
          message.success(response.message);
          window.history.back();
          // }
        })
        .catch((error) => {
          setIsLoading(false);
          message.error("Failed to submit Customer data. Please try again.");
          window.history.back();
        });
    } else {
      setIsLoading(false);
      message.error("Failed Fetch Customer data. Please try again.");
      window.history.back();
    }
  };


  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Customer</h2>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button className="btn btn-primary d-flex justify-content-center align-items-center m-1" onClick={() => window.history.back()}>
                <IoArrowBackOutline className="me-2" />
                <div>Back</div>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row

            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Email</label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="Email"
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Email" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} >
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Customer Name<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="customerName"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Customer Code"
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Customer Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                  <label className="font-bold">Contact<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="contact"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile no"
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers"
                      },
                      {
                        len: 10,
                        message: "Mobile number must be 10 digits"
                      }
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Mobile" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">PAN<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="PAN"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter PAN"
                      },
                      {
                        pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Please enter a valid PAN card number"
                      }
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Enter PAN" />
                  </Form.Item>


                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Country<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="country"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Country"
                        }
                      ]
                    }

                  >
                    <Input size="large" className="rounded border border-1" placeholder="Country" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">State<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="state"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter State"
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="State" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">City<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="city"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter City"
                        }
                      ]
                    }
                  >
                    <Input size="large" className="rounded border border-1" placeholder="City" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Address<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item name="address"
                    rules={
                      [
                        {
                          required: true,
                          message: "Please Enter Address"
                        }
                      ]
                    }
                  >
                    <Input.TextArea size="large" placeholder="Address" cols={3} rows={3} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>




            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5 ">
                  <label className="font-bold">ZipCode<span className="text-danger">*</span></label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ZipCode"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter ZipCode",
                      },
                    ]}
                  >
                    <Input size="large" className="rounded border border-1" placeholder="ZipCode" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>



            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5  ">
                  <label className="font-bold">Payment Terms</label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="PaymentTerms"
                  >
                    <Input size="large" className="rounded border border-1" placeholder="Payment Terms" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn btn-primary btn-lg" disabled={isLoading}>
                {isLoading ? 'Updating...' : 'Update Customer'}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default CustomerEditForm;
